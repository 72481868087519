<template>
  <div class="querycommunity">
    <LiefengContent>
      <template v-slot:title>{{ `小程序栏目统计` }}</template>
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true" class="search">
          <FormItem label="地区选择" :label-width="75">
            <!-- resetNum -->
            <LiefengCascaderCity @changeCasader="changeCasader" :orgCode="44" :width="200" />
          </FormItem>
          <Button type="primary" icon="ios-search" @click="request" style="margin-right: 10px">查询</Button>
          <Button type="primary" @click="daochu" style="margin-right: 10px">导出</Button>
          <Button type="primary" @click="tongbu" style="margin-right: 10px">同步数据</Button>
        </Form>
      </template>

      <template v-slot:contentArea>
        <LiefengTable
          :loading="loading"
          :talbeColumns="columns"
          :tableData="data" 
          :hidePage="true">
        </LiefengTable>
      </template>
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
import LiefengTable from "@/components/LiefengTable"
import treeVue from '../grid/children/tree.vue';

export default {
  components: {
    LiefengContent,
    LiefengCascaderCity,
    LiefengTable
  },
  data() {
    return {
      arr: [],
      columns: [
        {title: '栏目', key: 'columnName'},
        {title: '区', key: 'zoneName'},
        {title: '街道', key: 'streetName'},
        {title: '社区', key: 'communityName'},
        {title: '阅读人数', key: 'uvUser'},
        {title: '阅读次数', key: 'uv'},
        {title: '参与人数', key: 'pvUser'},
        {title: '参与次数', key: 'pv'},
      ],
      data: [],
      loading:false,
      dimensionList: [],
      state: false
    }
  },
  methods: {
    request() {
      if(this.arr.length == 0) {
        this.$Notice.error({
          title: '请选择地区进行查询',
        });
        return
      }
      this.loading = treeVue
      this.$get("/gateway/api/symanage/pc/orgcolumn/listOrgColumnLayout",{
        orgCode: this.arr[this.arr.length - 1]
      }).then(res => {
        this.loading = false
        this.data = res.dataList
      })
    },
    changeCasader(arr) {
      this.arr = arr
    },
    // 导出
    daochu() {
      if (this.data.length == 0) {
        this.$Notice.error({
          title: '当前页面数据为空，不能导出',
        });
        return
      }
      this.$Modal.confirm({
        title: "温馨提示",
        content: `<p>您正在导出小程序栏目统计，是否继续？</p>`,
        onOk: () => {
          this.$Message.loading({
            content: "正在导出数据，请稍等...",
            duration: 0
          });
          let tHeader = [
            "栏目",
            "区",
            "街道",
            "社区",
            "阅读人数",
            "阅读次数",
            "参与人数",
            "参与次数"
          ];
          let filterVal = [
            "columnName",
            "zoneName",
            "streetName",
            "communityName",
            "uvUser",
            "uv",
            "pvUser",
            "pv",
          ]
          let data = JSON.parse(JSON.stringify(this.data));
          this.$core.exportExcel(tHeader, filterVal, data, "行为数据分析列表");
          this.$Message.destroy();
        }
      });
    },
    // 同步
    tongbu() {
      if (this.state == false) {
        this.$get("/gateway/api/symanage/pc/orgcolumn/manualCalculation").then(res => {
          this.state = true
          if (res.code == "200") {
            if(this.state == true) {
              this.$Notice.success({
                title: '提示',
                desc: '同步成功'
              });
              if (this.arr.length != 0) {
                this.data = []
                this.request()
              }
              setTimeout(() => {
                this.state = false
              }, 3000)
            }
          }
        })
      } else {
        this.$Notice.warning({
          title: '提示',
          desc: '同步完成，请勿重复点击,3秒后在试'
        });
      }
    }
  }
}
</script>

<style scoped lang="less">
  .querycommunity {
    /deep/ .table > .ivu-table > .ivu-table-tip > table > tbody > tr > td{
      height: calc(100vh - 150px);
      border-left: 1px solid #f8f8f9;
      border-bottom: 1px solid #f8f8f9;
      border-right: 1px solid #f8f8f9;
    }
  }
</style>