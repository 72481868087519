<template>
  <div>
    <Form label-position="left" :label-width="150" style="width:50%">
      <FormItem>
        <span  slot="label">基础网格边界说明:</span>
        <Input
          style="width:500px"
          v-model="gridDesc"
          type="textarea"
          :rows="6"
          maxlength="200"
          placeholder="请输入基础网格边界说明"
          @on-change="changeInput"
        />
      </FormItem>
      <FormItem v-if="leader == true">
        <span class="validate" slot="label">选择类型</span>
        <RadioGroup v-model="type" @on-change	="changeRadio">
          <Radio label="1" :disabled="!showDisabled">管辖门牌号</Radio>
          <Radio label="2" :disabled="showDisabled">楼组<span v-if="!showDisabled">(已启用楼长管理模式，网格不可直接设置到户；如需网格直接管理到户，请删除楼组设置)</span></Radio>
        </RadioGroup>
      </FormItem>
      <FormItem>
        <span slot="label"  class="validate" v-if="leader == false">选择管辖门牌号:</span>
        <div class="tree-div" v-if="type == '1'">
          <Tree @on-check-change="selectChange" :data="treeData" show-checkbox ref="tree"></Tree>
        </div>
        <div v-if="type == '2' && leader == true" class="leader-div">
           <Table
                :loading="loading"
                 stripe
                :columns="talbeColumns"
                :data="tableList"
                width="300px"
                @on-selection-change="tableSelete"
                ></Table>
        </div>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import treeindexVue from '../../authCloud/column/treeindex.vue';
export default {
  props: {
    gridId: {
      type: String,
      default: () => {
        return "";
      }
    },
    orgCode: {
      type: String,
      default: () => {
        return "";
      }
    },
    dscString: {
      type: String,
      default: () => {
        return "";
      }
    },
    showNum:{
      type:Number,
      default:()=>{
        return 0 
      }
    },
    type:{
      type:String,
      default:()=>{
        return '1'
      }
    },
    leader:{
      type:Boolean,
      default:()=>{
        return true
      }
    },
    selectList:{
      type:Array,
      default:()=>{
        return ''
      }
    }
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      treeData: [],
      gridDesc: "",
      tableList:[],
      loading:false,
      talbeColumns:[
        {
                type:'selection',
                align:'center',
                width:80
          },
        {
                title:'名称',
                key:'gridName',
                align:'center',
          },
      ],
      showDisabled:false
    };
  },
  //方法集合
  methods: {
    changeRadio(val){
      this.$emit('changeRadio',val)
    },
    tableSelete(val){
      this.$emit('tableSelect',val)
    },
    //方法部分
    changeInput() {
      this.dscString = this.gridDesc;
    },
    selectChange(val) {
      this.$emit("selectTree", val);
    },
    // 接口部分
    getTree(data) {
      this.$post("/gx/pc/grid/queryRegionTree", {
        gridId: data,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: this.orgCode
      }).then(res => {
        if (res.code == 200) {
          this.treeData = res.dataList;
          if (this.treeData != null && this.treeData.length != 0) {
            let newList = this.formatter(this.treeData);
            this.$emit("selectTree", newList);
          }
        }
      });
    },
    getList(){
      this.$get('/gx/pc/grid/queryGridListTree',{
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        gridType:2,
        oemCode:parent.vue.oemInfo.oemCode,
        operatorOrgCode:parent.vue.loginInfo.userinfo.orgCode,
        staffName:parent.vue.loginInfo.userinfo.realName,
        orgCode:this.orgCode,
        gridId:this.gridId
      }).then(res=>{
        if(res.code == 200 ){
          // if(this.selectList != ''){
            if(res.dataList && res.dataList.length != 0){
              res.dataList.map(item=>{
                if(item.checked == true){
                  item._checked = true
                  item._disabled = false
                }else{
                  if(item.disable == true) item._disabled = true
                  else item._disabled = false
                }
                // if(item.checked == false && item.disable == true){
                //   item._checked = false
                //   item._disabled = true
                // }
              })
            }           
          // }
          this.tableList = res.dataList
          if(this.tableList.length != 0){
            this.showDisabled = false
            this.changeRadio('2')
          }else{
            this.showDisabled = true
            this.changeRadio('1')
          }
        }
      })
    },
    formatter(data) {
      let List = []
      data.map(item =>{
        if(item.checked == true){
          item.disabled = false
        }else{
          if(item.disable == true) item.disabled = true
          else item.disabled = false
        }
        if(item.children && item.children.length != 0) this.formatter(item.children)
      })
      List = data
      return List
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  watch: {
    gridId: {
      handler(val, newval) {
        if (val) {
          this.getTree(val);
          if(this.leader == true){
            setTimeout(()=>{
              console.log('进入了子组件',this.selectList);
              this.getList()
            },200)
            
          }
          
        }
      }
    },
    showNum: {
      handler(val, newval) {
        if (val) {
          if(this.dscString == null || this.dscString == ''){
            this.gridDesc = ''
          }else{
            this.gridDesc = this.dscString;
          }
        }
      }
    }
  }
};
</script>
<style lang='less' scoped>
//编写css
.input-div {
  display: flex;
  justify-content: flex-start;
}
.tree-div {
  height: 500px;
  border: 1px solid #e8eaec;
  width: 250px;
  overflow-y: auto;
  .ivu-tree{
    padding-left: 10px;
  }
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
.leader-div{
  max-height: 400px;
  overflow-y: scroll;
}
</style>